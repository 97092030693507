.App {
  text-align: center;
}

/* ====================================================banners==================================================== */
.services {
  margin-top: 11.5vh;
}

.banner img {
  width: 100%;
  padding: 0;
  margin: 0;
  object-fit: cover;
  margin-top: 11.5vh;
}

video {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  object-fit: cover;
  margin-top: 11.5vh;
}

@media only screen and (max-width: 768px) {
  .banner img {
    margin-top: 6vh;
  }

  video {
    margin-top: 6vh;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .banner img {
    margin-top: 6vh;
  }

  video {
    margin-top: 14vh;
    width: 100%;
  }
}

/* =======================================================homepage======================================================= */
.page h1 {
  font-size: 36px;
}

@media only screen and (max-width: 768px) {
  .page h1 {
    font-size: 26px;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .page h1 {
    font-size: 26px;
  }
}

/* ===============================================sitepage=============================================== */
.site {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site a {
  text-decoration: none;
  align-self: flex-end;
}

.enquire-button {
  background-color: red;
  padding: 2vh 4vh 2vh 4vh;
  color: white;
  border-radius: 50px;
  font-size: larger;
  align-self: flex-end;
  margin: 5vh 10vh 5vh 0;
}

.enquire-button:hover {
  background-color: var(--button-hover);
}

.gallery-container {
  /* margin-top: 5vh; */
  margin-bottom: 10vh;
}

@media only screen and (max-width: 768px) {
  .gallery-container {
    /* width: 90vw; */
    padding: 2vh;
  }

  .enquire-button {
    padding: 2vh 4vh 2vh 4vh;
    font-size: medium;
    margin: 2vh;
  }
}

/* ===========================================highlights page=========================================== */
#highlights-h1 {
  margin-top: 11.5vh;
  font-size: 36px;
}

@media only screen and (max-width: 768px) {
  #highlights-h1 {
    font-size: 26px;
  }
}

/* ============================================projects page============================================ */
#projects-h1 {
  margin-top: 11.5vh;
  margin-bottom: 6vh;
  font-size: 36px;
}
