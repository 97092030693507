.legacy h1 {
  font-size: 50px;
}

.legacy p {
  font-size: 90px;
  color: #000;
}

@media only screen and (max-width: 768px) {
  .legacy h1 {
    font-size: 36px;
  }

  .legacy p {
    font-size: 60px;
  }
}
