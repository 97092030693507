.testimonials {
  background-color: snow;
}

.testimonial-card li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonial-card {
  background-color: white;
  border: 1px solid black;
  padding-left: 2vw;
  padding-right: 2vw;
  width: 25vw;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}

.testimonial-card h3 {
  text-align: right;
}

#quote {
  position: relative;
  top: 0.3vh;
  right: 1vw;
  transform: scale(-1.8);
}

.by-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1vh;
  margin-right: 1vh;
}

@media only screen and (max-width: 768px) {
  .testimonial-card {
    width: 75vw;
    font-size: medium;
    padding: 1vh 10vw;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .testimonial-card {
    width: 40vw;
    height: 80vh;
    font-size: medium;
    /* padding: 1vh 10vw; */
  }
}
