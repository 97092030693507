.about img {
  width: 30vw;
}

.about {
  display: flex;
  justify-content: center;
  gap: 3vw;
  margin-top: 10vh;
}

.about-container {
  text-align: justify;
  width: 50vw;
  font-size: larger;
}

.about-container h1 {
  margin-top: 0;
}

.mission-vision {
  background-color: var(--off);
  padding: 0 2vw;
  margin-top: 0;
  margin-bottom: 10vh;
  width: 35vw;
}

.mission-vision h1 {
  margin-top: 3vh;
}

#byline {
  margin-left: 38vw;
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .about {
    flex-direction: column;
    margin-left: 10vw;
  }

  .about img {
    width: 85%;
  }

  .about-container {
    width: 85%;
    font-size: medium;
    margin-top: 0;
    margin-bottom: 1vh;
  }

  .about h1 {
    margin-top: 1vh;
    margin-bottom: 0;
    font-size: 26px;
  }

  .mission-vision {
    padding-left: 2vh;
    text-align: left;
  }

  #vision {
    margin-bottom: 8vh;
  }

  .mission-vision ul {
    padding-left: 10vw;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .about {
    flex-direction: column;
    margin-left: 10vw;
  }

  .about img {
    width: 85%;
  }

  .about-container {
    width: 85%;
    font-size: medium;
    margin-top: 0;
    margin-bottom: 1vh;
  }

  .about h1 {
    margin-top: 1vh;
    margin-bottom: 0;
    font-size: 26px;
  }

  .mission-vision {
    padding: 5vh;
    text-align: left;
  }

  #vision {
    margin-bottom: 8vh;
  }

  .mission-vision ul {
    padding-left: 5vw;
  }
}
