.associated-developers {
  padding-top: 3vh;
  padding-bottom: 5vh;
  background-color: white;
}

.associated-developers h1 {
  font-size: 36px;
  padding-bottom: 5vh;
}

.associated-developers li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.associated-developers img {
  width: 100%;
}

.associated-developer-card {
  width: 10vw;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .associated-developers h1 {
    font-size: 26px;
    margin-bottom: 0;
  }

  .associated-developer-card {
    width: 25vw;
    border: solid 3px white;
    margin-left: 1vh;
  }
}
