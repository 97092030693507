.projects-container {
  /* display: flex;
    flex-wrap: wrap;
    margin-left: 5vw;
    gap: 3vw; */
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 3vw;
  justify-content: center;
  margin-bottom: 4vh;
}

.project-card {
  width: 20vw;
  height: fit-content;
}

.project-card img {
  object-fit: cover;
  width: 100%;
}
