.whatsapp-fab {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 99999999;
}

@media only screen and (max-width: 768px) {
  .whatsapp-fab {
    bottom: 3vh;
    left: 80vw;
  }
}
