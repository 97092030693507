.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3vw;
  margin-top: 8vh;
  margin-bottom: 8vh;
}

.service-card {
  width: 40vw;
  gap: 2vw;
  height: auto;
  background-color: var(--off);
  border-radius: 20px;
  padding: 2vw;
  display: flex;
  text-align: justify;
}

.service-card img {
  width: 16vw;
  border-radius: 25px;
  object-fit: contain;
}

.service-card h2 {
  margin-top: 2vh;
  margin-bottom: 1vh;
}

@media only screen and (max-width: 768px) {
  .services {
    flex-direction: column;
    align-items: flex-start;
    gap: 5vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  .service-card {
    width: 85%;
    margin-left: 3vw;
    padding-left: 5vw;
    padding-right: 5vw;
    flex-direction: column;
    align-items: center;
  }

  .service-card img {
    width: 85%;
    border-radius: 25px;
    object-fit: contain;
    margin-top: 2vh;
  }

  .service-card-content h2 {
    margin-top: 3vh;
    font-size: large;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .services {
    flex-direction: column;
    align-items: flex-start;
    gap: 5vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  .service-card {
    width: 85%;
    margin-left: 3vw;
    padding-left: 5vw;
    padding-right: 5vw;
    gap: 5vw;
  }

  .service-card img {
    width: 20%;
    border-radius: 25px;
    object-fit: contain;
    margin-top: 2vh;
  }

  .service-card-content h2 {
    margin-top: 3vh;
    font-size: large;
  }
}
