.locations {
  margin-bottom: 10vh;
}

.locations h1 {
  font-size: 36px;
  margin-top: 8vh;
}

.location-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
}

.location-card {
  background-color: white;
  width: 28vw;
  height: 21vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

#title {
  color: white;
  text-decoration: none;
  font-size: x-large;
  margin-left: 1.5vw;
  margin-bottom: 2vh;
  font-weight: 600;
}

.location-card img {
  width: 100%;
}

.location-card:hover {
  box-shadow: 1px 8px 20px grey;
  transition: box-shadow 0.4s ease-in;
}

@media only screen and (max-width: 768px) {
  .locations h1 {
    font-size: 26px;
    margin-top: 3vh;
  }

  .location-container {
    flex-direction: column;
  }

  .location-card {
    width: 95%;
    margin-left: 2.5vw;
    margin-bottom: 1vh;
  }
}
