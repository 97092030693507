.footer {
  background-color: var(--charcoal);
  padding: 2vh 8vw 1vw 8vw;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.footer p {
  font-size: 16px;
  color: white;
  margin-bottom: 0;
  padding-bottom: 0;
}

#dev-p {
  margin-bottom: 0;
}

#dev:hover {
  color: red;
}

.footer-contents {
  display: flex;
  gap: 5vw;
}

.footer-subcontainer {
  display: flex;
  flex-direction: column;
  color: white;
  align-items: flex-start;
  gap: 1vh;
  width: 24vw;
}

.footer-subcontainer h2 {
  margin-bottom: 0.8vh;
}

.footer-link {
  display: flex;
  align-items: flex-start;
  gap: 1vw;
  padding-left: 0.5vw;
}

.footer-link a,
a:visited {
  text-decoration: none;
  padding: 0.1vh;
  padding-top: 0.3vh;
  padding-bottom: 1vh;
  color: white;
  text-align: left;
}

.footer-subcontainer a:hover {
  color: red; /* Change color on hover */
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 1vw;
  padding-top: 1vh;
  padding-left: 0.5vw;
}

.footer-socials a,
a:visited {
  color: white;
}

.direct {
  width: fit-content;
}

.iframe-container {
  position: relative;
  width: 35%;
}

.iframe-container iframe {
  position: absolute;
  top: 0.5vh;
  width: 95%;
  height: 90%;
}

#des {
  margin-top: 1vh;
}

#linkedin {
  text-decoration: none;
  color: #ccc;
}

#linkedin:hover {
  color: red;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
    font-size: small;
  }

  .footer h2 {
    margin-bottom: 10px;
  }

  .footer-contents {
    flex-direction: column;
    gap: 5vh;
  }

  .footer-link a {
    padding-top: 3px;
    padding-bottom: 12px;
  }

  .footer-subcontainer {
    gap: 0.2vh;
    width: 77vw;
  }

  .iframe-container {
    padding-bottom: 90%;
    width: 100%;
    position: relative;
    right: 42vw;
  }

  .footer p {
    font-size: small;
    margin-bottom: 2vh;
  }

  .copyright {
    margin-bottom: 10vh;
  }

  .copyright p {
    font-size: medium;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
    font-size: small;
  }

  .footer h2 {
    margin-bottom: 10px;
  }

  .footer-contents {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-link a {
    padding-top: 3px;
    padding-bottom: 12px;
  }

  .footer-subcontainer {
    gap: 0.2vh;
    width: 40vw;
    margin-left: 2vw;
  }

  .iframe-container {
    padding-bottom: 90%;
    width: 93%;
    position: relative;
    right: 42vw;
  }

  .footer p {
    font-size: medium;
    margin-bottom: 2vh;
  }

  .footer a {
    font-size: medium;
    margin-bottom: 2vh;
  }

  .copyright {
    margin-bottom: 4vh;
  }
}
