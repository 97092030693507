.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5vw;
  background-color: white; /* Optional background color */
  box-shadow: 0px 0px 20px 1px rgba(0, 89, 79, 0.1);
  position: fixed;
  z-index: 1;
  width: 100%;
}

.logo {
  width: 17vw;
}

.header img {
  margin: 0;
}

.nav {
  display: flex;
  gap: 2.8vw;
  position: relative;
  left: 20vw;
}

.header .nav a,
a:visited {
  color: var(--charcoal);
  text-decoration: none;
  font-size: 22px;
  align-self: center;
}

.header .nav a:hover {
  color: red;
}

.header button {
  display: none;
}

.header-phone {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}

.header-phone:hover {
  color: red;
}

.phone-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  background-color: red;
  padding: 0 1vw 0 1vw;
  border-radius: 50px;
  color: white;
  position: relative;
  left: 10vw;
}

.header a {
  text-decoration: none;
}

.phone-no {
  margin: 2vh 0 2vh 0;
}

.header .phone-button:hover {
  background-color: var(--button-hover);
}

@media only screen and (max-width: 768px) {
  .nav {
    display: none;
  }

  .logo {
    width: 40%;
  }

  .header .nav {
    display: none;
  }

  .header button {
    display: flex;
  }

  .phone-button {
    background-color: white;
    color: var(--charcoal);
    position: relative;
    left: 18vw;
  }

  .phone-no {
    display: none;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .nav {
    display: none;
  }

  .logo {
    width: 20%;
  }

  .header .nav {
    display: none;
  }

  .header button {
    display: flex;
  }

  .phone-button {
    background-color: white;
    color: var(--charcoal);
    position: relative;
    left: 33vw;
  }

  .phone-no {
    display: none;
  }
}
